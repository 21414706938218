import React from 'react';
import Helmet from 'react-helmet';

import Contact from '../../components/contact';
import { Layout } from '../../components/Layout/Layout';

export const ContactPage = () => {
    return (
      <Layout>
        <Helmet>
          <title>Contact Us - Devwares</title>
          <meta
            name="title"
            content="Contact Us - Devwares"
          />
          <meta
            name="description"
            content="Leave a message for us and we will contact you as soon as possible"
          />
          <meta
            property="og:title"
            content="Contact Us - Devwares "
          />

          <meta
            property="og:description"
            content="Leave a message for us and we will contact you as soon as possible"
          />
          <meta
            property="twitter:title"
            content="Contact Us - Devwares"
          />
          <meta
            property="twitter:description"
            content="Leave a message for us and we will contact you as soon as possible"
          />
          <meta property="og:site_name" content="Devwares" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Contact />
      </Layout>
    );
};