import styled from 'styled-components';
import AddressImage from '../images/address.png';
import PhoneImage from '../images/phone.png';
import EmailImage from '../images/email.png';

const ContactFormContainer = styled.section`
  position: relative;
  min-height: 60vh;
  width: 100%;
  margin: 3em 0px;

  @media (min-width: 48em) and (max-width: 64em) {
    min-height: 40vh;
  }

  @media screen and (max-width: 48em) {
    min-height: 50vh;
  }
`;

const ContactInfoCanvas = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
  @media screen and (max-width: 52em) {
    width: 100%;
    display: block;
  }
`;

const ContactInfoSection = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 52em) {
    width: 50%;
  }
  @media screen and (max-width: 52em) {
    width: 100%;
    text-align: center;
    margin-bottom: 2em;
  }
`;

const AddressBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3em;

  @media screen and (max-width: 40em) {
    text-align: left;
    margin-top: 1em;
  }
`;

const AddressBoxRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5em;

  @media screen and (max-width: 769px) {
    justify-content: center;
  }
`;

const ContactBox = styled.div`
  // width: 90%;
  p {
    margin-bottom: 0px;
  }
`;

const IconArea = styled.div`
  // width: 10%;
  margin-right: 10px;

  @media screen and (max-width: 40em) {
    margin-right: 0.5em;
  }
`;

const ContactFormBox = styled.div`
  @media screen and (max-width: 40em) {
    padding-bottom: 1.5em;
  }
  @media screen and (min-width: 52em) {
    width: 50%;
  }
`;

const SocialIconsContainer = styled.div`
  margin-top: 2em;
`;

const SocialsIconStyles = styled.span`
  height: 45px;
  width: 45px;
  padding: 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.18);
  color: #000;
  font-size: 1.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto 0.3em;
  svg {
    vertical-align: inherit;
  }
`;

const Button = styled.button`
  margin: 20px 0px;
  padding: 15px 50px;
  border: none;
  background: #ccc9ff;
  box-shadow: 0px 5px 5px rgba(75, 93, 104, 0.1);
  border-radius: 16px;
  color: #3329e0;
  font-weight: 800;
  cursor: pointer !important;
`;

// styled icons
const StyledAddressIcon = styled.img.attrs({
  src: AddressImage,
  alt: 'address icon',
})`
  color: #2bc4e1;
  object-fit: cover;
  height: 2em;
`;

const StyledPhoneIcon = styled(StyledAddressIcon).attrs({
  src: PhoneImage,
  alt: 'phone icon',
})``;

const StyledEmailIcon = styled(StyledAddressIcon).attrs({
  src: EmailImage,
  alt: 'email icon',
})``;

// Form Utils
const StyledForm = styled.form`
  width: 90%;

  @media screen and (max-width: 52em) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledNameInput = styled.input.attrs({
  type: 'text',
  placeholder: 'Name',
})`
  width: 100%;
  height: 3.4em;
  border: ${props => (props.whiteborder ? '1.2px solid #fff' : '1.2px solid #b9b8b8')};
  border-radius: 3px;
  padding: 2px 20px;

  &:focus {
    outline: none;
  }
`;

const StyledEmailInput = styled(StyledNameInput).attrs({
  type: 'email',
  placeholder: 'Email Address',
})`
  margin: 2em 0px;
`;

const StyledTextarea = styled.textarea.attrs({
  placeholder: 'Type Message',
})`
  padding: 20px 20px;
  width: 100%;
  min-height: 16em;
  border: #b9b8b8 solid 1px;
  border-radius: 3px;

  &:focus {
    outline: none;
  }
`;

export {
  ContactFormContainer,
  ContactInfoSection,
  ContactFormBox,
  ContactInfoCanvas,
  AddressBox,
  AddressBoxRow,
  ContactBox,
  IconArea,
  SocialIconsContainer,
  SocialsIconStyles,
  StyledAddressIcon,
  StyledPhoneIcon,
  StyledEmailIcon,
  StyledForm,
  StyledNameInput,
  StyledEmailInput,
  StyledTextarea,
  Button,
};
