import React, { useRef } from 'react';
import firebase from 'gatsby-plugin-firebase';
import NotificationSystem from 'react-notification-system';
import {
  ContactFormContainer,
  ContactInfoSection,
  ContactFormBox,
  ContactInfoCanvas,
  AddressBox,
  AddressBoxRow,
  ContactBox,
  IconArea,
  SocialIconsContainer,
  SocialsIconStyles,
  // StyledAddressIcon, StyledPhoneIcon,
  StyledEmailIcon,
  StyledForm,
  StyledNameInput,
  StyledEmailInput,
  StyledTextarea,
  Button,
} from './contact.styles';

const Contact = () => {
  const db = firebase.firestore();

  const notificationSystem = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const formRef = useRef();

  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;

    notification.addNotification({
      title,
      message,
      level,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const message = messageRef.current.value;
    let docRef = db.collection('feedback');

    docRef
      .add({
        name,
        email,
        message,
      })
      .catch(error => {
        addNotification({
          title: 'Error',
          message: 'Message not sent',
          level: 'error',
        });
      })
      .then(() => {
        addNotification({
          title: 'Mesage Sent',
          message: 'Your message has been received by Devwares',
          level: 'success',
        });
        formRef.current.submit();
      });
    e.currentTarget.submit();
  };

  return (
    <ContactFormContainer id="appcontact">
      <NotificationSystem ref={notificationSystem} />
      <ContactInfoCanvas className="container">
        <ContactInfoSection>
          <h4 className="font-bold text-3xl mb-3">FEEDBACK</h4>
          <h2>Send us a Message</h2>
          <AddressBox>
            {/* <AddressBoxRow>
                <IconArea>
                  <StyledAddressIcon />
                </IconArea>
                <ContactBox>
                  <p>80 Lorem ipsum dolor adipisin elit sit amet consectur</p>
                </ContactBox>
              </AddressBoxRow>
              <AddressBoxRow>
                <IconArea>
                  <StyledPhoneIcon />
                </IconArea>
                <ContactBox>
                  <p>+143-645764-847776</p>
                  <p>+234-6544-847776</p>
                </ContactBox>
              </AddressBoxRow> */}
            <AddressBoxRow>
              <IconArea>
                <StyledEmailIcon />
              </IconArea>
              <ContactBox>
                <p>contact@devwares.com</p>
              </ContactBox>
            </AddressBoxRow>
          </AddressBox>
          <SocialIconsContainer>
            <SocialsIconStyles>
              <a
                href="https://www.facebook.com/Devwares-102291481719158/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f" />
              </a>
            </SocialsIconStyles>
            <SocialsIconStyles>
              <a href="https://twitter.com/devwares?s=09" target="_blank" rel="noreferrer">
                <i className="fab fa-twitter" />
              </a>
            </SocialsIconStyles>
            <SocialsIconStyles>
              <a href="https://instagram.com/devwares" target="_blank" rel="noreferrer">
                <i className="fab fa-instagram" />
              </a>
            </SocialsIconStyles>
            <SocialsIconStyles>
              <a href="https://www.linkedin.com/company/devwares" target="_blank" rel="noreferrer">
                <i className="fab fa-linkedin" />
              </a>
            </SocialsIconStyles>
          </SocialIconsContainer>
        </ContactInfoSection>

        <ContactFormBox>
          <StyledForm
            name="contact"
            method="post"
            data-netlify="true"
            netlify-honeypot="bot-field"
            action="/"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <input type="hidden" name="form-name" value="contact" />
            <StyledNameInput ref={nameRef} type="text" name="name" />
            <StyledEmailInput ref={emailRef} type="email" name="email" />
            <StyledTextarea ref={messageRef} name="message" />
            <Button>Send Message</Button>
          </StyledForm>
        </ContactFormBox>
      </ContactInfoCanvas>
    </ContactFormContainer>
  );
};

export default Contact;
